<template>
    <workspace-wrapper>
        <template #workspace>
            <blackjack21-workspace :state-data="stateData"/>
        </template>
    </workspace-wrapper>
</template>

<script>
import WorkspaceWrapper from "../../Common/WorkspaceWrapper";
import Blackjack21Workspace from "./Blackjack21Workspace";

export default {
    props: {
        stateData: {
            type: Object,
            required: true,
            default() {
                return {
                    currentSpinResult: {
                        playerHands: [{
                            isSplitted: null,
                            place: null,
                            firstSplitHand: null,
                            secondSplitHand: null,
                        }],
                        dealerHand: null,
                    },
                    denom: null
                }
            }
        }
    },
    components: {
        WorkspaceWrapper,
        Blackjack21Workspace
    }
}
</script>

<style scoped>

</style>
